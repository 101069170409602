<template>
  <div class="memeberdatail_box" style="margin-top: 10px;">
    <el-card>
      <div class="box_main">
        <div class="main_top_img">
          <div class="main_top_left">
            <span v-if="allData.orderStatus==0">{{'待支付'}}</span>
            <span v-if="allData.orderStatus==1">{{'已取消'}}</span>
            <span v-if="allData.orderStatus==2">{{'已支付'}}</span>
            <span v-if="allData.orderStatus==3">{{'已完成'}}</span>
            <div class="dashed"></div>
          </div>
          <div class="main_top_right">
            <!-- // -->
            <div class="order_box">
              <div class="box_order">
                <div class="order_dotted_left"></div>
                <div class="order_icon iconfont icon-tijiaodingdan"></div>
                <div class="order_dotted_left">---------------</div>
              </div>
              <div class="submit_order">提交订单</div>
              <div class="submit_time">{{ timer.createTime }}</div>
            </div>
            <div class="order_box">
              <div class="box_order">
                <div class="order_dotted_left">---------------</div>
                <div class="order_icon iconfont">
                  <img style="width: 40px;height: 40px;" v-if="allData.orderStatus=== 3 ||allData.orderStatus === 2 ||allData.orderStatus === 1"
                    src="../../../assets/images/paymentRed.png">
                  <img style="width: 40px;height: 40px;" v-else src="../../../assets/images/payment.png">
                </div>
                <div class="order_dotted_left">---------------</div>
              </div>
              <div class="submit_order">用户付款成功</div>
              <div class="submit_time">{{ timer.paymentTime }}</div>
            </div>
            <div class="order_box">
              <div class="box_order">
                <div class="order_dotted_left">---------------</div>
                <div class="order_icon iconfont icon-yifahuo"></div>
                <div class="order_dotted_left">---------------</div>
              </div>
              <div class="submit_order">商家发货</div>
              <div class="submit_time">{{ timer.deliveryTime }}</div>
            </div>
            <div class="order_box">
              <div class="box_order">
                <div class="order_dotted_left">---------------</div>
                <div class="order_icon iconfont icon-shouye"></div>
                <div class="order_dotted_left"></div>
              </div>
              <div class="submit_order">完成</div>
              <div class="submit_time">{{ timer.receiveTime }}</div>
            </div>
          </div>
        </div>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">基本信息</div>
        </div>
        <el-table :data="tableData" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="combineOrderNo" label="订单号" width="">
          </el-table-column>
          <el-table-column prop="mobile" label="用户账号" width="">
          </el-table-column>
          <el-table-column prop="nickName" label="用户昵称" width="">
          </el-table-column>
          <el-table-column label="订单来源" width="">
            <template #default="scope">
              <div v-if="scope.row.sourceType==0">{{'APP'}}</div>
              <div v-if="scope.row.sourceType==1">{{'小程序'}}</div>
              <div v-if="scope.row.sourceType==2">{{'PC端'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="支付方式" width="">
            <template #default="scope">
              <div v-if="scope.row.payType==0">{{'未支付'}}</div>
              <div v-if="scope.row.payType==1">{{'白条'}}</div>
              <div v-if="scope.row.payType==2">{{'支付宝'}}</div>
              <div v-if="scope.row.payType==3">{{'微信'}}</div>
              <div v-if="scope.row.payType==4">{{'农行'}}</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">团长信息</div>
        </div>
        <el-table :data="brokerData" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="realName" label="团长姓名" width="">
          </el-table-column>
          <el-table-column prop="mobile" label="团长手机号" width="">
          </el-table-column>
          <el-table-column prop="invokeCode" label="团长邀请码" width="">
          </el-table-column>
        </el-table>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">收货人信息</div>
        </div>
        <el-table :data="shrList" border style="margin-left: 40px; margin-top: 10px"
          :header-cell-style="tableHeaderColor" size="medium">
          <el-table-column prop="receiverName" label="收货人" width="">
          </el-table-column>
          <el-table-column prop="receiverPhone" label="收货手机号" width="">
          </el-table-column>
          <el-table-column prop="" label="收货地址" width="">
            <template #default="scope">
              <div>
                {{ scope.row.receiverCity }}
                {{ scope.row.receiverProvince }}
                {{ scope.row.receiverRegion }}
                {{ scope.row.receiverDetailAddress }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="esal_tal" style="margin-top: 50px">
          <div class="dids"></div>
          <div class="didses">商品信息</div>
        </div>
        <div v-for="(item, index) in allData.brokerOrderData" :key="index" style="margin-bottom: 20px">
          <div class="table">
            <div class="table_one">订单号{{ item.orderNo}}</div>
            <div class="table_two">{{ item.storeName }}</div>
            <!-- <span style="color: red;margin-left: 20px;">
				分账已转自营
			</span> -->
          </div>
          <el-table :data="item.brokerOrderGoodsDataList" border style="margin-left: 40px; margin-top: 10px"
            :header-cell-style="tableHeaderColor" size="medium">
            <el-table-column label="商品图片" width="">
              <template #default="scope">
                <img class="goodsimg" :src="scope.row.mainImageUrl" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="name" class="longtext" label="商品名称" width="">
            </el-table-column>
            <el-table-column label="规格" width="">
              <template #default="scope">
                <span>{{scope.row.bulkUnit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="meterUnit" class="longtext" label="计量单位" width="">
            </el-table-column>
            <el-table-column prop="quality" class="longtext" label="商品重量" width="">
            </el-table-column>
            <el-table-column prop="basicUnit" class="longtext" label="基本单位" width="">
            </el-table-column>
            <el-table-column prop="goodsNum" label="数量" width="">
            </el-table-column>
            <el-table-column prop="priceStr" label="单价 (团长利润)" width="">
              <template #default="scope">
                   <span>{{ scope.row.priceStr }}</span>  <span style="color: red;">{{  '('+ scope.row.profitAmountStr +')'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="totalPriceStr" label="小计 (团长利润)" width="">
              <template #default="scope">
                   <span>{{ scope.row.totalPriceStr }}</span>  <span style="color: red;">{{  '('+ scope.row.totalProfitAmountStr+')' }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="table_bottom">
            <div class="bottom_orderzi">订单备注：</div>
            <div class="bottom_btn">
              <el-input :value='item.remark' disabled placeholder="请输入内容"></el-input>
            </div>
            <div class="bottom_goods">
              <div class="goods_total">合计：</div>
              <div class="goods_money">￥{{ allData.totalAmountStr }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <div class="goback" @click="$router.push('/group/order/list')">返回</div>
  </div>
</template>
<script>
import { orderDetail } from '@/api/group'
export default {
  data() {
    return {
      tableData: [{
        memberId: "",
        combineOrderNo: "",
        mobile: "",
        nickName: "",
        sourceType: "",
        payType: "",
      },],
      brokerData:[
        {
          realName:'',
          mobile:"",
          invokeCode:''
        }
      ],
      allData: {},
      orderList: [], // 订单信息
      member: {}, //会员信息
      shrList: [],
      timer: {
        createTime: "", //初始化时间
        paymentTime: "", //支付时间
        deliveryTime: "", //发货时间
        receiveTime: "", //结束时间
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    // table
    tableHeaderColor({
      rowIndex
    }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;font-weight: 700;";
      }
    },
    //获取详情数据
    list() {
      let id = this.$route.query.id;
      orderDetail({
        id
      }).then((res) => {
        this.allData = res.data;
        this.orderList = res.data.brokerOrderData;
        this.member = res.data.memberData;
        // 用户信息
        this.tableData[0].combineOrderNo = res.data.combineOrderNo;
        this.tableData[0].mobile = res.data.memberData.mobile;
        this.tableData[0].nickName = res.data.memberData.nickName;
        this.tableData[0].sourceType = res.data.sourceType;
        this.tableData[0].payType = res.data.payType;
        this.tableData[0].memberId = res.data.memberId;
       // 团长信息
       this.brokerData[0].realName = res.data.brokerData.realName;
        this.brokerData[0].mobile = res.data.brokerData.mobile;
        this.brokerData[0].invokeCode = res.data.brokerData.invokeCode;
        // 订单信息
        let objconsignee = {
          receiverName: this.allData.receiverName,
          receiverCity: this.allData.receiverCity,
          receiverProvince: this.allData.receiverProvince,
          receiverRegion: this.allData.receiverRegion,
          receiverDetailAddress: this.allData.receiverDetailAddress,
          receiverPhone: this.allData.receiverPhone,
        };
        this.shrList.push(objconsignee);


      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main{
  background:#fff !important;
}
.goback{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  line-height: 40px;
  width: 140px;
  height: 40px;
  // background: #279dfb;
  border-radius: 4px;
  color: #333;
  border: #ccc 1px solid;
}
.numClass {
  display: flex;
  // justify-content: center;
}

.foot {
  width: 1200px;
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.goodsimg {
  width: 80px;
  height: 80px;
}

.memeberdatail_box {
  margin-left: 0px;
}

#red {
  color: red;
}

/deep/ .domain {
  border: 1px solid #efefef;
  width: 100%;
}

.box_main {
  width: 1200px;
  height: 100%;

  .main_top_img {
    display: flex;
    width: 1125px;
    height: 170px;

    .main_top_left {
      width: 285px;
      height: 170px;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      line-height: 170px;
      color: red;
      position: relative;

      .dashed {
        border-right: 1px dotted #999999;
        width: 1px;
        height: 60%;
        // background-color: red;
        display: inline-block;
        position: absolute;
        top: 45px;
        right: 0px;
      }
    }

    .main_top_right {
      width: 800px;
      height: 170px;
      margin-left: 50px;
      display: flex;

      .order_box {
        width: 255px;
        height: 120px;
        margin-top: 55px;

        // background-color: red;
        .box_order {
          display: flex;

          .order_dotted_left {
            width: 200px;
            margin-top: 10px;
          }

          .order_icon {
            width: 50px;
            height: 50px;
            font-size: 40px;
            text-align: center;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .submit_order {
          text-align: center;
          font-size: 14px;
        }

        .submit_time {
          margin-top: 5px;
          text-align: center;
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }

  .esal_tal {
    display: flex;

    .dids {
      margin-left: 20px;
      width: 15px;
      height: 15px;
      background-color: rgba(194, 128, 255, 1);
    }

    .didses {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .table {
    margin-top: 10px;
    width: 1198px;
    height: 40px;
    display: flex;
    margin-bottom: -10px;
    line-height: 40px;
    margin-left: 40px;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    .table_one {
      margin-left: 15px;
      font-size: 14px;
      font-weight: 700;
    }

    .table_two {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .table_bottom {
    background-color: rgb(246 248 249);
    margin-bottom: 10px;
    width: 1198px;
    height: 60px;
    display: flex;
    margin-bottom: -10px;
    line-height: 40px;
    margin-left: 40px;
    border-bottom: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;

    .bottom_orderzi {
      margin-left: 20px;
      line-height: 60px;
    }

    .bottom_btn {
      width: 670px;
      height: 40px;
      margin-top: 10px;
      // background-color: blue;
    }

    .bottom_goods {
      width: 400px;
      display: flex;
      line-height: 60px;

      .goods_total {
        margin-left: 200px;
      }

      .goods_money {
        color: #ff0000;
        font-weight: bold;
      }
    }
  }
}
</style>
